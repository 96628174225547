import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_HOST}`,
  headers: {
    accept: 'application/json',
    app_name: 'siswa',
  },
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  if (!response) {
    return notification.warning({
      message: 'Error Response Please Contact Developers',
    })
  }
  const { data } = response
  if (data) {
    if (typeof data === 'string') {
      notification.warning({
        message: data,
      })
    } else {
      notification.warning({
        message: data.message || data.data || 'Unknown error',
      })
    }
  }
  return response || error
})

export default apiClient
